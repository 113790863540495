@charset "UTF-8";

@import "mixin";
@import "settings/reset";
@import "settings/base";

// Noto Sans Japanese
// Zen Maru Gothic
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Zen+Maru+Gothic:wght@400;500;700&display=swap');

body {
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  min-width: 1100px;
  @include sp {
    min-width: auto;
  }
}

* {
  box-sizing: border-box;
}