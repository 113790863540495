@charset "UTF-8";
// SASS関数を上書きする関数。CSS関数を使えるようにするため。
@function min($values...) {
  @return unquote('min(#{$values})');
}
@function max($values...) {
  @return unquote('max(#{$values})');
}
@function z($name, $childname: 0) {
  $getkey: map-get($z-map, $name);
  $counter: 1;
  $is-index: 0;
  @if $childname != 0 {
    @return index($getkey, $childname);
  }

  @each $map-key, $map-value in $z-map {
    @if $map-key == $name {
      $is-index: $counter;
    }
    $counter: $counter + 1;
  }

  @return $is-index;
}

$ff-zen-maru-gothic: 'Zen Maru Gothic', sans-serif;
$fw-medium: 500;
// z-index管理。bodyの子要素のみ管理します。
$z-map: (
        index-banner: true,
        index-fixed_link: true,
        index-fixed_sidelink: true,
        seminar-nav: true,
        cmn-header: true,
        smb-tos: true,
        jquery-modal-blocker: true
);

// sp
@mixin sp {
  @media (max-width: 428px) {
    @content;
  }
}
@mixin hover_op_08 {
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
}